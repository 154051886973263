import {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom'

export const Landing2   = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
    return (
        
        // <section id='second' className="second">
        //     <div className='mission'>Our mission is your transformation and impact.</div>
        //     <div className='missionSmall'>and this is how:</div>
        //     <div className='blokken'  data-aos="fade-up">
        //         <div className='blok' >
        //             <div className='icon'><BsBrush/></div>
        //             <div className='title'>Design</div>
        //             <hr/>
        //             <div className='text'>We specialize in designing websites that not only have a stunning visual appeal but also effectively attract and engage a larger customer base made for you.</div>
        //         </div>
        //         <div className='blok'  >
        //             <div className='icon'><BsLightbulb/></div>
        //             <div className='title'>Innovate</div>
        //             <hr/>
        //             <div className='text'>We innovate to ensure your website possesses a captivating design, creating a unique digital experiencethat effortlessly attracts and engages a wider customer base.</div>
        //         </div>
        //         <div className='blok'  >
        //             <div className='icon'><BsNodePlus/></div>
        //             <div className='title'>Create</div>
        //             <hr/>
        //             <div className='text'>Through our creations, we strive to deliver an exceptional experience for both you and your customers, ensuring utmost satisfaction and delight at every interaction.</div>
        //         </div>
        //     </div>


        //     <div className='projects'   data-aos="fade-up">
        //         <div className='leftProjects'>
        //             <div className='titleProjects' >Our Projects</div>
        //             <hr/>
        //             <div className='textProjects' >At bocky, we create innovative solutions that exceed expectations. With a blend of creativity and expertise, we deliver tailored projects from website design to software development. Our comprehensive approach ensures a seamless and impactful experience, transforming your vision into reality and propelling your business forward.</div>
        //             <div className='buttonsProjects'><Link to="/projects" className='viewAllBtn'>VIEW OUR PROJECTS</Link><Link to="/gallery" className='galaryBtn'>GALLERY</Link></div>

        //         </div>
        //         <div className='rightProjects'>
        //         </div>
        //     </div>

           
        // </section>
        <section id='second' className='landing2Section'>
            <div className='orangeBack'>
            <div className='projects'   data-aos="fade-up">
                 <div className='leftProjects'>
                     <div className='titleProjects' >Our Projects</div>
                     <hr/>
                     <div className='textProjects' >At bocky, we create innovative solutions that exceed expectations. With a blend of creativity and expertise, we deliver tailored projects from website design to software development. Our comprehensive approach ensures a seamless and impactful experience, transforming your vision into reality and propelling your business forward.</div>
                     <div className='buttonsProjects'><Link to="/projects" className='viewAllBtn'>VIEW OUR PROJECTS</Link><Link to="/gallery" className='galaryBtn'>GALLERY</Link></div>

                 </div>
                 <div className='rightProjects'>
                 </div>
                 </div>
            </div>

            
        </section>
        
    )
}
