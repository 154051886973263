
import React from 'react';
import Loader from '../Loader';
export const Smbgsm = () => {

    return (
        <div className='galleryRow '>
            <div className='galleryBlockSM'> 
            WORK          
            </div>
            <div className='galleryBlockBG'>
            IN
            </div>
            <div className='galleryBlockSM smsms2'>
            PROGRESS
           
            </div>
            
         
        </div>
    )
}