
import React from 'react';
import { Link } from 'react-router-dom';

export const Navbar = ({type}) => {
    return (
        <header className={type === "orange" ? "orange": ""}>
            <nav>
                <Link to='/home' className='logo'>
                    <h4>bocky.</h4>
                </Link>
               
            </nav>
        </header>
    )
}