
import {useEffect} from 'react'
import { Navbar } from './Navbar'
import AOS from 'aos';
import 'aos/dist/aos.css';
import data from '../data/projects.json'
import { Footer } from './Footer'
import { Link } from 'react-router-dom'
import { Smbgsm } from './galleryComp/Smbgsm';
import { Bgsmsm } from './galleryComp/Bgsmsm';
import { Smsmbg } from './galleryComp/Smsmbg';



const Gallery = () => {
    
    useEffect(() => {
        document.title = "bocky. | Gallery"
      }, [])
    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0)
      }, []);
   return (
        <div>
            <Navbar type="orange"/>
            <main>
                <section className='gallerySection' >
                    <h2>GALLERY</h2>
                    <div>Have a quick vieuw of the products we deliver</div>
                    <div className='scrolldown2'>
                    <a href='#second' className='scrolldownText smnone'>Scroll down</a>
                    <div class="line"></div>
                </div>
                </section>
                <section className='theGal'>
                    <Smbgsm data-aos="fade-up"/>
                    <Smsmbg data-aos="fade-left" />
                    <Bgsmsm data-aos="fade-up"/>
                </section>
            </main>
            <div>
                   
        </div>
            <Footer type="none"/>
        </div>
    )
}

export default Gallery