
import React from 'react';
import Loader from '../Loader';

export const Smsmbg = () => {

    return (
        <div className='galleryRow'>
            <div className='galleryBlockSM'>
                WORK
            </div>
            <div className='galleryBlockSM'>  
            IN         
            </div>
            <div className='galleryBlockBG smsms2'>
                PROGRESS
                
                </div>
        </div>
    )
}