import { Routes, Route, Navigate } from "react-router-dom";
// import { NotFound } from "./components/NotFound";
import { ComingSoon } from "./components/ComingSoon";
import { NotFound } from "./components/NotFound";
import "./App.css";
import Landing from "./components/landing/Landing";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Detail from "./components/Detail";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/soon" element={<ComingSoon />} />
        <Route path="/home" element={<Landing />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:linkname" element={<Detail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/home" element={<LandingPage />} /> */}
        {/* <Route path="/events" element={<EventList />} /> */}
      </Routes>
    </HelmetProvider>
  );
}

export default App;
