
import React from 'react';

export const Bgsmsm = () => {

    return (
        <div className='galleryRow'>
             <div className='galleryBlockBG'>
                WORK
            </div>
            <div className='galleryBlockSM'>
                IN
            </div>
            <div className='galleryBlockSM smsms2'>    
            PROGRESS       
            </div>
           
        </div>
    )
}