
import {useRef, useState, useEffect} from 'react'
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import emailjs from '@emailjs/browser';
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet-async'

const Contact = () => {
    const [status , setStatus] = useState("");
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_fq4s6ue', 'template_awinvmg', form.current, 'gT_gOA5lBtaFMtoJe')
          .then((result) => {
              setStatus("Message sent!")
          }, (error) => {
              setStatus("An error occurred, please try again")
          });
          e.target.reset()
      };

      useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

    return (
        <div className='contactcont'>
            <Helmet prioritizeSeoTags>
            <title>bocky. | Contact</title>
                <meta
                name="description"
                content="bocky. Contact us for more information about our services."
                />
                <meta name="author" content="Casper De Bock" />
                    <meta name="keywords" content="bocky,modern websites,websites,bocky.,web design, contact" />
                    <meta name="title" content="bocky. | Contact" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://bocky.be/contact" />
                    <meta property="og:title" content="bocky." />
                    <meta property="og:image" content="../../../public/bocky.png" />
            </Helmet>
            <Navbar/>
            <main>
                <div className='mission'>Love to hear from you, <br/>Get in touch</div>
                <form ref={form} data-aos="fade-up" onSubmit={sendEmail} className="mailForm">
                    <div className='rijForm'>
                        <div className='colomForm'>
                        <div className="inputlabel">Your name:</div>
                        <input
                            placeholder="Richard Hendricks"
                            name="name"
                            type="text"
                            className="inputField"
                            required
                        />
                        </div>
                        <div className='colomForm'>
                       <div className="inputlabel">Email:</div>
                        <input
                            placeholder="richard@example.com"
                            name="email"
                            type="email"
                            className="inputField"
                            required
                        />
                        </div>
                    </div>
                    <div className='rijForm'>
                    <div className='colomForm'>
                    <div className="inputlabel">Category:</div>
                    <select name="category" className="inputFieldcat">
                        <option value="general">General</option>
                        <option value="design">Design</option>
                        <option value="web">Web</option>
                        <option value="app">App</option>
                    </select>
                    </div>
                    <div className='colomForm'>
                    <div className="inputlabel">Subject:</div>
                        <input
                            placeholder="Subject"
                            name="subject"
                            type="text"
                            className="inputField"
                            required
                        />
                    </div>
                    </div>

                    <div className='rijFormFull'>
                        
                        <div className="inputlabel">Message:</div>
                        <textarea
                            placeholder="Your message..."
                            name="text"
                            className="inputFieldBig"
                            required
                        />
                        <button
                            type="submit"
                            className="btnadd"
                        >
                            {' '}
                            SEND
                        </button>
                        <div className={status === "Message sent!" ? "green" : "red"}>{status}</div>
                        </div>
                    </form>
            </main>
            <Footer type="bottom"/>
        </div>
    )
}

export default Contact