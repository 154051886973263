import React, { useEffect } from 'react';
import { useState } from 'react';
import '../App.css';

export const ComingSoon = () => {

    //countdown
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    // const now = new Date().getTime();
    const countDownDate = new Date("Aug 22, 2023 10:30:00").getTime();

     setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        });
    }, 1000);


    useEffect(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        });
    }, [countDownDate]);





    return (
     
        <div className="">
            <div className='coming-soon'>
                <h1 className="coming-soon__title">bocky</h1>
                <h2 className="coming-soon__subtitle">Under construction...</h2>
            
                
                <div className="coming-soon__countdown">
                    <div className="coming-soon__countdown__item">
                        <span className="coming-soon__countdown__item__number">{countdown.days}</span>
                        <span className="coming-soon__countdown__item__text">Days</span>

                    </div>
                    <div className="coming-soon__countdown__item">
                        <span className="coming-soon__countdown__item__number">{countdown.hours}</span>
                        <span className="coming-soon__countdown__item__text">Hours</span>
                    </div>
                    <div className="coming-soon__countdown__item">
                        <span className="coming-soon__countdown__item__number">{countdown.minutes}</span>
                        <span className="coming-soon__countdown__item__text">Minutes</span>
                    </div>
                    <div className="coming-soon__countdown__item">
                        <span className="coming-soon__countdown__item__number">{countdown.seconds}</span>
                        <span className="coming-soon__countdown__item__text">Seconds</span>
                    </div>

                </div>
            </div>
            <div className='cone_wrapper'>
                <svg className='coming-soon__cone'  viewBox="0 0 590 532" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M259.231 69.325C268.524 32.0812 321.476 32.0812 330.769 69.325L437.891 497.812H516.25C521.14 497.812 525.83 499.755 529.287 503.213C532.745 506.67 534.688 511.36 534.688 516.25C534.688 521.14 532.745 525.83 529.287 529.287C525.83 532.745 521.14 534.687 516.25 534.687H73.75C68.8601 534.687 64.1704 532.745 60.7127 529.287C57.255 525.83 55.3125 521.14 55.3125 516.25C55.3125 511.36 57.255 506.67 60.7127 503.213C64.1704 499.755 68.8601 497.812 73.75 497.812H152.109L259.231 69.325Z" fill="#FF3D00"/>
                </svg>
            </div>
        </div>
    );
}
