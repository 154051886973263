
import React from 'react'
import { Navbar } from '../Navbar'
import { Landing1 } from './Landing1'
import { Landing2 } from './Landing2'
import { Footer } from '../Footer'
import { Helmet } from 'react-helmet-async'

const Landing = () => {
    return (
        <>
            <Helmet>
            <title>bocky. | Improving your online presence</title>
                <meta
                name="description"
                content="bocky. is a web development company that specializes in creating websites and web applications. We make sure your company has a stunning online presence."
                />
                  <meta name="author" content="Casper De Bock" />
                    <meta name="keywords" content="bocky,modern websites,websites,bocky.,web design" />
                    <meta name="title" content="bocky. | Improving your online presence" />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://bocky.be/" />
                    <meta property="og:title" content="bocky." />
                    <meta property="og:image" content="../../../public/bocky.png" />
            </Helmet>
            <Navbar/>
            <main>
            <Landing1/>
            <Landing2/>  
            </main>
            <Footer/>
            
        </>
    )
}

export default Landing