
import {BsInstagram,BsDiscord,BsTwitter} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import {ReactComponent as Splash} from '../assets/splash.svg'
import LandingImage from '../assets/landingPageImage.webp'



export const Landing1 = () => {
    return (
        <section className='landing1Section'>
            <div className="leftSection">
                <div className="slogan">Improving & creating your web</div>
                <div className="sloganHit">Design.</div>

                <div className="infoLeftSection">
                    <div className="infotext nr1"> 
                        Your website is the first thing people see when they search for your company.
                        It's important to make a good first impression.
                    </div>
                    <div className="infotext"> 
                        We are here to help you with that.
                         <span className="highlight">It's time to improve.</span>
                    </div>
                </div>

                <div className="socials">
                        <Link rel="noreferrer" target={'_blank'} to="https://www.instagram.com/bocky.be/"><BsInstagram className='iconSocial'/></Link>
                        <Link rel="noreferrer" target={'_blank'} to="https://discord.gg/QzjJ5dV2NX"><BsDiscord className='iconSocial'/></Link>
                        <Link rel="noreferrer" target={'_blank'} to="https://twitter.com/bocky_be"><BsTwitter className='iconSocial'/></Link>

                </div>

                <div className='contactlanding'>
                <Link to="/projects">
                    <button className='ourWork'>PROJECTS</button>
                </Link>
                <Link to="/contact">
                    <button className='contactBTN'>CONTACT US<Splash className="splashwh"></Splash></button>
                </Link>
                </div>

              
            </div>
        
            <div className="rightSection">
                {/* <div className="driehoeken">
                    <Polygon1 className="polygon1"/>
                    <Polygon2 className="polygon2"/>
                    <Polygon3 className="polygon3"/>
                </div> */}
                <img src={LandingImage} alt='Landing page image bocky. Working person improving a system' data-aos="fade-left" className="landingThing"/>

      
            </div> 
           

            <div className='scrolldown'>
                    <a href='#second' className='scrolldownText'>Scroll down</a>
                    <div class="line"></div>
                </div>
        
        <div className="phoneButtons">
                <Link to="/projects">
                    <button className='ourWorkBTNPhone'>PROJECTS</button>
                </Link>
            <Link className='contactBTNPhone' to="/contact">
               CONTACT US
            </Link>
          
        </div>

        </section>
    )
}

