
import React from 'react';

import {BsInstagram,BsDiscord,BsTwitter} from 'react-icons/bs'
import {HiMail} from 'react-icons/hi'
import { Link } from 'react-router-dom';

export const Footer = ({type}) => {

    return (
        <footer className={type === "bottom" ? "botfooter": type === "none"? "none" : ""}>
            <Link to="/contact" className='footerText'><HiMail/><span>info@bocky.be</span></Link>
            {/* <div className='footerText'>©Bocky</div> */}
            <div className='footericons'>
                <div>Socials:</div>
                <Link rel="noreferrer" target={'_blank'} to="https://www.instagram.com/bocky.be/" ><BsInstagram className='iconfooter'/></Link>
                <Link rel="noreferrer" target={'_blank'} to="https://discord.gg/QzjJ5dV2NX"><BsDiscord className='iconfooter'/></Link>
                <Link rel="noreferrer" target={'_blank'} to="https://twitter.com/bocky_be"><BsTwitter className='iconfooter'/></Link>
            </div>
           


        </footer>
    )
}